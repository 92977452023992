import FinanceApi from '@/services/FinanceApi';

const report = async (config) => {
    return FinanceApi.get('/invoices/report', config);
}

const reportJob = async (config) => {
    return FinanceApi.get('/invoices/report-job', config);
}

const customerInvoices = async () => {
    return FinanceApi.get('/customer/invoices');
}

export default {
    report,customerInvoices,reportJob
}
