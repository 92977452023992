<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_invoices')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_invoices')" :is-filter="false"/>
            </template>

            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-striped" v-if="data && data.length">
                        <tr>
                            <th>{{$t('date')}}</th>
                            <th>{{$t('amount')}}</th>
                            <th>{{$t('currency')}}</th>
                            <th class="w-40"></th>
                        </tr>
                        <tr v-for="invoice in data">
                            <td>{{invoice.date}}</td>
                            <td class="text-right">{{invoice.amount | formatNumber}}</td>
                            <td>{{invoice.currency}}</td>
                            <td><a :href="invoice.url" target="_blank" class="btn btn-outline-info">{{$t('view')}}</a></td>
                        </tr>
                    </table>
                    <div v-else>
                        <div class="text-center py-5 my-5 w-100" v-if="data">
                            <div class="mb-5">
                                <i class="ri-file-2-line ri-5x text-muted"></i>
                            </div>
                            <h4 class="mb-4 text-black-50">{{ $t('you_dont_have_any_invoice') }}</h4>
                        </div>
                        <div class="spinner over text-center mt-5" v-else>
                            <b-spinner variant="primary"></b-spinner>
                        </div>
                    </div>
                </div>
            </div>

        </app-layout>
    </div>
</template>
<script>
import InvoiceService from "@/services/InvoiceService";
import AppLayout from "@/layouts/AppLayout";

import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout
    },
    metaInfo() {
        return {
            title: this.$t("my_invoices"),
        };
    },
    data() {
        return {
            data:null
        };
    },
    created() {
        this.getInvoices();
    },
    methods: {
        getInvoices(){
            InvoiceService.customerInvoices()
            .then(response=>{
                this.data=response.data.data;
            })
            .catch(e=>{
                this.showErrors(e);
            });
        }
    },
};
</script>
